import baseAuthAdminQuery from './baseAuthAdminQuery';

export async function trackAnalyticsEvent(eventName, properties = {}) {
  const response = await baseAuthAdminQuery({
    path: 'analytics_events',
    method: 'post',
    data: {
      analytics_event: {
        event: eventName,
        properties,
      },
    },
  });

  return response?.data;
}
