import React from 'react';
import cx from 'classnames';
import { useConfirm, useResendCode } from '@separate/hooks/login';
import { useTranslation } from '@separate/hooks/translation';
import styles from './index.module.scss';
import InputForm from './InputForm';
import useConfirmationInputFormState from './useConfirmationInputFormState';
import { useLoginFlow } from '../../loginFlowContext';

export default function ConfirmationCode() {
  const { t } = useTranslation();
  const { currentPhoneNumber, exitLoginFlow } = useLoginFlow();
  const inputFormState = useConfirmationInputFormState();
  const { confirmation } = inputFormState.value;
  const disabled = !inputFormState.isValid;

  // Submit code
  const { isLoading: isConfirmLoading, confirm } = useConfirm({ onSuccess, onError });

  function onSuccess(response) {
    exitLoginFlow?.();
    window.scrollTo(0, -100);
  };

  function onError({response}) {
    const fieldErrors = {
      confirmation: [
        t(`validations.incorrect`, {field: `^${inputFormState?.labelFor("confirmation")}`}),
      ],
    };
    inputFormState.mergeErrors(fieldErrors);
  };

  // Resend code
  const { isResendLoading, resendCode } = useResendCode({ onResendSuccess, onResendError });

  const onResendSuccess = ({ response }) => {
    // The resend is fire and forget.  This is a no-op.
  };
  const onResendError = ({ response }) => {
    console.log(response.status)
  };

  const handleSubmit = () => {
    confirm({ phoneNumber: currentPhoneNumber, code: confirmation });
  };

  const handleResend = () => {
    resendCode({ phoneNumber: currentPhoneNumber });
  };

  const isLoading = isConfirmLoading || isResendLoading;

  return (
    <>
      <h2 className={styles.title}>{t(`login.enter_the_confirmation_code`)}</h2>
      <p className={styles.subtitle}>{t('login.please_enter_5_digit_code')}</p>
      <InputForm inputFormState={inputFormState}
        loading={isLoading}
        onSubmit={handleSubmit}
        disabled={disabled}
      />

      <p className={cx(styles.resendCode, 'd-block mt-4 mb-0 text-center')}>
        {t('login.resend_label')}
        <span onClick={handleResend} className="ms-1">
          {t('login.resend_code')}
        </span>
      </p>
      <p className={cx(styles.footer, 'mt-4')}>
        {t('login.footer.text')}
        <a href="tel:(726) 202-1213">{t('login.footer.number')}</a>
      </p>
    </>
  );
}
