import React from 'react';
import posthog from 'posthog-js';
import PropTypes from 'prop-types';
import PageLoader from '@separate/components/PageLoader';
import useAnalyticsLoader from '@separate/hooks/loadAnalytics';

const PostHogContext = React.createContext(null);

export function PostHogProvider({ children, posthogHost, posthogKey }) {
  const [isLoading, setLoading] = React.useState(true);

  function analyticsLoaded() {
    setLoading(false);
  }

  useAnalyticsLoader(posthogHost, posthogKey, analyticsLoaded);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false); // this should be safe, as React should not trigger a re-render if it's already false
    }, 2500);
  });

  return (
    <PostHogContext.Provider
      value={posthogKey ? posthog : undefined}
    >
      {isLoading ? (
        <PageLoader />
      ) : (
        <>{children}</>
      )}
    </PostHogContext.Provider>
  );
}

PostHogProvider.propTypes = {
  children: PropTypes.node.isRequired,
  posthogHost: PropTypes.string,
  posthogKey: PropTypes.string,
};

export function usePostHogProvider() {
  return React.useContext(PostHogContext);
}
