import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from '@/components/Button';
import FieldErrorsList from '@separate/components/FieldErrorsList';
import SuccessModal from '@separate/components/SuccessModal';
import {
  DEPENDENT_AGE,
  DEPENDENT_AGE_QUESTION,
  DEPENDENT_TYPE,
  DEPENDENT_TYPE_QUESTION,
  INPUT_FORM,
  OVER_18,
  SPOUSE,
} from '@separate/constants/dependents';
import { useAddDependent } from '@separate/hooks/dependents';
import { useLocale, useTranslation } from '@separate/hooks/translation';
import { useCurrentUser } from '@separate/hooks/user';
import { Dependent, DependentGradient } from '@separate/icons';
import { CloseCircle } from '@separate/icons';
import { yearMonthDate } from '@separate/utilities/datetime';
// import AddOrEditDependentModal from '../AddOrEditDependentModal';
import styles from './index.module.scss';
import InputForm from './InputForm';
import { useDependentsInputFormState } from './InputForm/useDependentsInputFormState';
import QuestionSelections from './QuestionSelections';

export default function AddDependentsOffcanvas({ show, onHide, container, refetchDependents }) {
  const [view, setView] = React.useState(DEPENDENT_TYPE);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  // const [showAddOrEditDependent, setShowAddOrEditDependent] = React.useState(false);
  const { user } = useCurrentUser();
  const locale = useLocale();
  const userId = user?.id;
  const { t } = useTranslation();

  const [typeAnswer, setTypeAnswer] = React.useState({ selected: '' });
  const [ageAnswer, setAgeAnswer] = React.useState({ selected: '' });
  const isSpouse = typeAnswer.selected === SPOUSE;
  const isAdult = isSpouse || ageAnswer.selected === OVER_18;

  const onSuccess = (result) => {
    onHide();
    setShowSuccessModal(true);
  };

  const onError = ({ response }) => {
    const fieldErrors = {
      validation: [
        response.data?.error,
      ],
    };
    inputFormState.mergeErrors(fieldErrors);
  };

  const handleSuccessfulModalHide = () => {
    setShowSuccessModal(false);
    refetchDependents();
  };

  const { isLoading: addDependentLoading, addUserDependent } = useAddDependent({ onSuccess, onError });
  const loading = addDependentLoading;

  const inputFormState = useDependentsInputFormState(isAdult);
  const inputFormValues = inputFormState.value;
  const setInputFormValues = inputFormState.setValue;
  const fieldErrors = inputFormState.fieldErrors;
  const clearFieldErrors = inputFormState.clearFieldErrors;
  const disabled = !inputFormState.isValid;

  const data = {
    ...inputFormValues,
    birthday: yearMonthDate(inputFormValues.birthday),
    is_spouse: isSpouse,
  };

  const handleHide = () => {
    onHide();
    setInputFormValues({});
    setAgeAnswer({ selected: '' });
    setTypeAnswer({ selected: '' });

    setTimeout(() => {
      clearFieldErrors();
      setView(DEPENDENT_TYPE);
    }, 1000);
  };

  const handleSubmit = () => {
    addUserDependent({
      data,
    });
  };

  const handleQuestionSelections = () => {
    // if (view === DEPENDENT_AGE && isAdult || view === DEPENDENT_TYPE && isAdult) {
    //   setShowAddOrEditDependent(true);
    // }
    if (view === DEPENDENT_TYPE && isSpouse || view === DEPENDENT_AGE) {
      setView(INPUT_FORM);
    }
    if (view === DEPENDENT_TYPE &&  !isSpouse) setView(DEPENDENT_AGE);
  };

  return (
    <>
      <Offcanvas
        backdropClassName={styles.backdrop}
        className={styles.container}
        placement='bottom'
        show={show}
        onHide={handleHide}
        container={container}
      >
        <Offcanvas.Header className={styles.header}>
          <Offcanvas.Title className={styles.title}>
            {t('user.dependents.add_dependent')}
          </Offcanvas.Title>
          <Button
            className={styles.closeButton}
            onClick={handleHide}
            iconButton
          >
            <CloseCircle />
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {view === INPUT_FORM && (
            <>
              <div className={styles.dependentContainer}>
                <div className={cx(styles.dependentHeader, 'd-flex align-items-center')}>
                  <DependentGradient />
                  <h6>{t('user.dependents.dependent_information')}</h6>
                </div>
                <div className={styles.dependentBody}>
                  <InputForm
                    inputFormState={inputFormState}
                    loading={loading}
                    adult={isAdult}
                  />
                </div>
              </div>
              <FieldErrorsList fieldErrors={fieldErrors} />
              <div className='d-block'>
                <Button
                  className={styles.submitCta}
                  onClick={handleSubmit}
                  disabled={disabled}
                  loading={loading}
                >
                  {t('user.dependents.submit')}
                </Button>
              </div>
            </>
          )}
          {view === DEPENDENT_AGE && (
            <QuestionSelections
              title={t('user.dependents.age.title')}
              questionOptions={DEPENDENT_AGE_QUESTION}
              loading={loading}
              answer={ageAnswer}
              setAnswer={setAgeAnswer}
              onSubmit={handleQuestionSelections}
            />
          )}
          {view === DEPENDENT_TYPE && (
            <QuestionSelections
              title={t('user.dependents.type.title')}
              questionOptions={DEPENDENT_TYPE_QUESTION}
              loading={loading}
              answer={typeAnswer}
              setAnswer={setTypeAnswer}
              onSubmit={handleQuestionSelections}
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>
      {/* <AddOrEditDependentModal
        show={showAddOrEditDependent}
        container={container}
        onHide={() => setShowAddOrEditDependent(false)}
        description={(
          <p className={styles.description}>
            {t(`user.dependents.add_or_edit_dependent.${isSpouse ? 'add_spouse_text' : 'add_child_text'}`)}
            <span>{t('user.dependents.add_or_edit_dependent.contact')}</span>
          </p>
        )}
        title={t('user.dependents.add_or_edit_dependent.add_title')}
      /> */}
      <SuccessModal
        icon={<Dependent />}
        title={t('user.dependents.success_modal.title')}
        description={t('user.dependents.success_modal.text')}
        buttonText={t('user.dependents.success_modal.cta')}
        onHide={handleSuccessfulModalHide}
        container={container}
        show={showSuccessModal}
      />
    </>
  );
}

AddDependentsOffcanvas.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  container: PropTypes.any,
  refetchDependents: PropTypes.func,
};
