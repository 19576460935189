import React from 'react';
import PropTypes from 'prop-types';
import {
  ACCOUNT_CANCELLATION_VIEW,
  EDIT_BASIC_INFO_VIEW,
  EDIT_DEPENDENTS_VIEW,
  EDIT_PAYMENT_METHOD_VIEW,
  EDIT_SMS_PREFERENCES_VIEW,
  MAIN_VIEW,
  PAYMENT_STATUS_CHECKER,
  PAY_PAST_DUE_VIEW,
} from '@/constants/profile';
import { useGetMissedPayment } from '@/hooks/premium';
import { useCurrentUser } from '@separate/hooks/user';
import { isFeatureEnabled } from '@separate/utilities/features';
import EditBasicInfo from './EditViews/EditBasicInfo';
import PoderCardAlertModal from './PoderCardAlertModal';
import AccountCancellation from './steps/AccountCancellation';
import AccountInfo from './steps/AccountInfo';
import EditDependents from './steps/EditDependents';
import Main from './steps/Main';
import PaymentStatusChecker from './steps/PaymentStatusChecker';
import SMSPreferences from './steps/SMSPreferences';
import { useRouter } from 'next/router';
import { useIsSingleSigningOn } from '@separate/hooks/singleSignOn';

export default function Profile(props) {
  const { onDismiss, showPayment } = props;
  const { query } = useRouter();
  const { user, isLoggedIn } = useCurrentUser();
  const { userHasMissedPayment } = useGetMissedPayment();
  const { hasSingleSignedOn } = useIsSingleSigningOn();
  const allowAddDependents = isFeatureEnabled('allow_dependents');
  const hidePodercard = isFeatureEnabled('hide_podercard_references');

  const [view, setView] = React.useState(MAIN_VIEW);
  const [showPodercardAlert, setShowPodercardAlert] = React.useState(false);

  const ref = React.createRef();

  function backToMain() {
    setView(MAIN_VIEW);
  }

  function handleEditBasic() {
    if (user.is_podercard_user && !hidePodercard) {
      setShowPodercardAlert(true);
    } else {
      setView(EDIT_BASIC_INFO_VIEW);
    }
  }

  function onUpdateBasicInfoSuccess() {
    backToMain();
  }

  function onUpdatePaymentInfoSuccess() {
    onDismiss();
  }

  React.useEffect(() => {
    if (!showPayment) return null;

    setView(PAYMENT_STATUS_CHECKER);

    return () => {
      setView(MAIN_VIEW);
    };
  }, [showPayment]);

  React.useEffect(() => {
    if(hasSingleSignedOn || isLoggedIn) {
      if (!user?.is_premium_member) return;
      if (query.showProfile === 'true' && query.showDependents === 'true') {
        setView(EDIT_DEPENDENTS_VIEW);
      };
    }
  }, [hasSingleSignedOn, isLoggedIn]);

  return (
    <div ref={ref}>
      {view === PAYMENT_STATUS_CHECKER && (
        <PaymentStatusChecker
          onPayPastDue={() => setView(PAY_PAST_DUE_VIEW)}
          onNoPaymentInfo={() => setView(MAIN_VIEW)}
          onEditPaymentMethod={() => setView(EDIT_PAYMENT_METHOD_VIEW)}
        />
      )}

      {view === MAIN_VIEW && (
        <Main
          onEdit={handleEditBasic}
          onDismiss={onDismiss}
          onPayPastDue={() => setView(PAY_PAST_DUE_VIEW)}
          onEditPaymentMethod={() => setView(EDIT_PAYMENT_METHOD_VIEW)}
          onEditSMSPreferences={() => setView(EDIT_SMS_PREFERENCES_VIEW)}
          onEditDependents={() => setView(EDIT_DEPENDENTS_VIEW)}
        />
      )}

      {view === EDIT_BASIC_INFO_VIEW && (
        <EditBasicInfo
          isPoderCardUser={user?.is_podercard_user}
          isPremiumMember={user?.is_premium_member}
          onUpdateBasicInfoSuccess={onUpdateBasicInfoSuccess}
          onCancel={backToMain}
        />
      )}

      {(view === EDIT_DEPENDENTS_VIEW && allowAddDependents) && (
        <EditDependents
          backToMain={backToMain}
        />
      )}

      {view === PAY_PAST_DUE_VIEW && (
        <AccountInfo
          onCancel={backToMain}
          onDismiss={onDismiss}
          onSuccess={onUpdatePaymentInfoSuccess}
          missedPayment={userHasMissedPayment}
        />
      )}

      {view === EDIT_PAYMENT_METHOD_VIEW && (
        <AccountInfo onDismiss={onDismiss} onCancel={backToMain} />
      )}

      {view === EDIT_SMS_PREFERENCES_VIEW && (
        <SMSPreferences
          onAccountCancellationView={() => setView(ACCOUNT_CANCELLATION_VIEW)}
          backToMain={backToMain}
        />
      )}

      {view === ACCOUNT_CANCELLATION_VIEW && (
        <AccountCancellation
          onEditSMSPreferences={() => setView(EDIT_SMS_PREFERENCES_VIEW)}
        />
      )}

      <PoderCardAlertModal
        show={showPodercardAlert}
        onHide={() => setShowPodercardAlert(false)}
      />
    </div>
  );
}

Profile.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  showPayment: PropTypes.bool,
};
