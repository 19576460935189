import { INELIGIBLE_ZIP_CODES } from "@separate/constants/ineligibleZipCodes";
import { PREMIUM_PLATINUM, PREMIUM_SILVER, PREMIUM_TELEMEDICINE, PREMIUM_LEGACY, PREMIUM_HEALTH_AND_WELLNESS } from "@separate/constants/premium";

const INELIGIBLE_STATES = ['UT', 'VT', 'WA'];

const GOOD_STANDING_STATUSES = [
  'active',
  'trialing',
];

const PROVISIONING_STATUSES = [
  'provisioning',
  'provisioning_confirmation',
];

const ERROR_STATUSES = [
  'deprovisioning_confirmation_error',
  'deprovisioning_confirmation',
];

// The observed values from Stripe when a payment has been missed ... _so far_
const PAY_DUE_STATUSES = [
  'past_due',
  'unpaid',
];

export function isStateEligible(state) {
  return !INELIGIBLE_STATES.includes(state);
}

export function isZipCodesEligible(zipCode) {
  return !INELIGIBLE_ZIP_CODES.includes(zipCode);
}

export function isSubscriptionPastDue(subscription) {
  return PAY_DUE_STATUSES.includes(subscription?.status);
}

// We want true here as long as the subscription is live, whether in good standing or not.
export function isSubscriptionActive(subscription) {
  return (
    GOOD_STANDING_STATUSES.includes(subscription?.status) || isSubscriptionPastDue(subscription)
  );
}

export function isSubscriptionProvisioning(subscription) {
  return (
    PROVISIONING_STATUSES.includes(subscription?.status)
  );
}

export function isSubscriptionError(subscription) {
  return ERROR_STATUSES.includes(subscription?.status);
}

export function isSubscriptionLegacy(subscription) {
  return !subscription?.last4 && isSubscriptionActive(subscription);
}

export function isAnnual(subscription) {
  return subscription?.interval == 'year';
}

export function isSubscriptionMonthlyActive(subscription) {
  return isSubscriptionActive(subscription) && subscription?.interval === 'month';
}

export function isSubscriptionFreeTrial(subscription) {
  return subscription?.free_pending_subscription && subscription?.trial_period_days && subscription?.trial_period_days > 0;
}

export function isSubscriptionHaveCoupon(subscription) {
  return subscription?.price_with_discount < subscription?.price_without_discount;
}

export function isPremiumTelemedicine(user) {
  return user?.premium_ui_group_name === PREMIUM_TELEMEDICINE;
}

export function isPremiumPlatinum(user) {
  if(user?.premium_ui_group_name) {
    return user?.premium_ui_group_name === PREMIUM_PLATINUM || user?.premium_ui_group_name === PREMIUM_HEALTH_AND_WELLNESS;
  }

  if(user?.subscription?.product_ui_group_name) {
    return user?.subscription?.product_ui_group_name === PREMIUM_PLATINUM || user?.subscription?.product_ui_group_name === PREMIUM_HEALTH_AND_WELLNESS;
  }
}

export function isPremiumSilver(user) {
  if(user?.premium_ui_group_name) {
    return user?.premium_ui_group_name === PREMIUM_SILVER;
  }

  if(user?.subscription?.product_ui_group_name) {
    return user?.subscription?.product_ui_group_name === PREMIUM_SILVER;
  }
}

export function isPremiumLegacy(user) {
  return user?.premium_ui_group_name === PREMIUM_LEGACY;
}
