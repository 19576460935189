import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import AddDependentsOffcanvas from '@separate/components/Dependents/AddDependentsOffcanvas';
import { useTranslation } from '@separate/hooks/translation';
import { Add, DependentGradient } from '@separate/icons';
import { Meatballs } from '@separate/icons';
import AddOrEditDependentModal from '../AddOrEditDependentModal';
import styles from './index.module.scss';

export default function DependentsList({ container, userDependents, refetchDependents }) {
  const [showAddDependents, setShowAddDependents] = React.useState(false);
  const [showAddOrEditDependent, setShowAddOrEditDependent] = React.useState(false);
  const { t } = useTranslation();

  const handleEditDependent = () => {
    setShowAddOrEditDependent(true);
  };

  const handleAddDependents = () => {
    setShowAddDependents(true);
  };

  return (
    <div className={styles.container}>
      {userDependents.map((dependent, index) => (
        <div className={cx(styles.dependentsCard, 'mt-3 d-flex align-items-center justify-content-between')} key={index}>
          <div className='d-flex flex-column'>
            <h4>{`${dependent.first_name} ${dependent.last_name}`}</h4>
            <p className='mt-1'>
              {t('user.dependents.birthday', { birthday: dependent.birthday, interpolation: { escapeValue: false }})}
            </p>
          </div>
          <Button
            className={styles.editCta}
            iconButton
            onClick={handleEditDependent}
          >
            <Meatballs />
          </Button>
        </div>
      ))}
      <div
        className={cx(styles.newDependents, 'mt-3')}
        onClick={handleAddDependents}
      >
        <DependentGradient className={styles.dependentIcon} />
        <p>{t('user.dependents.add_dependent')}</p>
        <Button
          className={styles.addCta}
          iconButton
        >
          <Add />
        </Button>
      </div>
      <AddDependentsOffcanvas
        show={showAddDependents}
        onHide={() => setShowAddDependents(false)}
        container={container}
        refetchDependents={refetchDependents}
      />
      <AddOrEditDependentModal
        show={showAddOrEditDependent}
        container={container}
        onHide={() => setShowAddOrEditDependent(false)}
        description={(
          <p className={styles.description}>
            {t('user.dependents.add_or_edit_dependent.edit_text')}
            <span>{t('user.dependents.add_or_edit_dependent.contact')}</span>
          </p>
        )}
        title={t('user.dependents.add_or_edit_dependent.edit_title')}
      />
    </div>
  );
}

DependentsList.defaultProps = {
  userDependents: [],
};

DependentsList.propTypes = {
  userDependents: PropTypes.array.isRequired,
  container: PropTypes.any,
  refetchDependents: PropTypes.func,
};
