// NOTE: Care should be taken when importing anything from this file.  Importing `* as analytics` is a bad idea
// because it can name clash (shadow) the globally available `analytics` object that segment creates.  Such
// shadowing is likely harmless as you should never be calling segments `analytics` directly anywhere in the code
// (rather you should always go through this abstraction layer) but it still should be avoided.
import posthog from 'posthog-js';
import { trackAnalyticsEvent } from '@separate/api/sep/analytics';
import { BENEFIT_ENGAGEMENT_COMPLETED, PODERSALUD_PRODUCT_ID } from '@separate/constants/analytics';
import { isFeatureEnabled } from '@separate/utilities/features';
import { getUtmTags } from "@separate/utilities/utmTagsStore";
import { compact } from './object';

export * from '@separate/constants/analytics';

export function logEvent(eventName, properties = {}) {
  if (isFeatureEnabled('console_log_segment_events')) {
    console.log("Segment enabled?", !!window?.analytics);
    console.log("Logging analytics event", eventName);
    console.log("Logging analytics data", properties);
  }

  trackAnalyticsEvent(eventName, properties);
  posthog?.capture(eventName, properties);
}

export function logPageView(utmTags) {
  const properties = { referrer: document.referrer || window.WelcomeTech?.referrer, ...utmTags };

  if (isFeatureEnabled('console_log_segment_events')) {
    console.log("Logging analytics page view with data", properties);
  }
  logEvent('page_view', properties);
}

export function setAnalyticsPid(pid) {
  if (pid == undefined) return; // Using == to include null in the check

  if (isFeatureEnabled('console_log_segment_events')) {
    console.log("Setting analytics pid", pid);
  }

  posthog?.identify(pid, {
    is_logged_in: true,
  });
}

export function setAnalyticsUser(user) {
  if (!user) return;
  console.log("Setting analytics user pid", user.pid);
  posthog?.identify(user.pid);
}

export function logoutAnalyticsUser() {
  posthog?.reset();
}

export function logBenefitsEngagement(benefit, type) {
  const utmTags = getUtmTags();

  if (typeof window === 'undefined') return;

  let properties = {
    benefit,
    type,
    product_id: PODERSALUD_PRODUCT_ID,
    service_utilization: 'true',
  };

  const urlParamsKey = [...new URL(window.location.href).searchParams.keys()];
  const utmTagsAvailableInUrl = urlParamsKey.some((param) => param.includes("utm_"));

  if (!utmTagsAvailableInUrl) {
    properties = {
      ...properties,
      ...utmTags,
    };
  }

  logEvent(BENEFIT_ENGAGEMENT_COMPLETED, compact(properties));
}

export function logPersonalInformation(event, properties = {}) {
  const eventProperties = {
    product_id: PODERSALUD_PRODUCT_ID,
    step_number: '1',
    step_name: 'pii_entry',
    ...properties,
  };

  logEvent(event, eventProperties);
}

export function logPlanSelection(event) {
  const eventProperties = {
    product_id: PODERSALUD_PRODUCT_ID,
    step_name: 'plan_selection',
    step_number: '2',
  };

  logEvent(event, eventProperties);
}
