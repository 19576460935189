import React from 'react';
import posthog from 'posthog-js';

export default function useAnalyticsLoader(posthogHost, posthogKey, loadedCallback) {
  function initializePostHog() {
    posthog.init(posthogKey, {
      api_host: posthogHost,
    });

    if (loadedCallback) {
      console.log("Posthog ready!");
      loadedCallback();
    }
  }
  /* eslint-disable */
  React.useEffect(() => {
    if (!posthogKey) {
      return;
    }

    initializePostHog();
    return;
  }, []);
  /* eslint-enable */
}
