const user = {
  first_name: "Nombre",
  last_name: "Apellido",
  name: "Nombre",
  email: "Correo electrónico",
  email_optional : "Correo electrónico (optional) (SP)",
  gender: "Género",
  address: "Domicilio",
  phone: "Celular",
  phone_number: "Celular",
  birthday_with_format: "Fecha de nacimiento mm/dd/aaaa",
  birthday: "Fecha de nacimiento",
  gender_options: {
    male: "Hombre",
    female: "Mujer",
    unspecified: "Prefiero no decir",
  },
  culture: {
    mexico: "Mexico",
    el_salvador: "El Salvador",
    guatemala: "Guatemala",
    united_states: "EEUU",
    colombia: "Colombia",
    puerto_rico: "Puerto Rico",
    costa_rica: "Costa Rica",
    cuba: "Cuba",
    nicaragua: "Nicaragua",
    ecuador: "Ecuador",
    unknown: "No se",
    honduras: "Honduras",
    argentina: "Argentina",
    bolivia: "Bolivia",
    chile: "Chile",
    dominican_republic: "República Dominicana",
    panama: "Panama",
    paraguay: "Paraguay",
    peru: "Peru",
    spain: "España",
    uruguay: "Uruguay",
    venezuela: "Venezuela",
    other: "Otro"
  },
  culture_id: "Seleccione el país de origen de su familia",
  other_culture_name: "Especificar el país de origen de su familia",
  mexico_state_id: "¿De qué estado de México proviene su familia?",
  interests: "Intereses",
  language: "Idioma",
  language_options: {
    spanish: "Español",
    english: "Inglés",
  },
  profile: {
    header_title: "Mi Cuenta",
    logout_cta: "Cerrar sesión",
    account_cancellation: {
      title: 'Cancelación de cuenta SEP',
      text_one: 'Para cancelar su cuenta SEP y/o borrar su información personal, por favor envíe un correo a ',
      text_two: ' con su solicitud. Asegúrese de incluir la siguiente información en su correo:',
      details: [
        {
          text: 'Nombre completo',
        },
        {
          text: 'Domicilio',
        },
        {
          text: 'Número de celular',
        },
        {
          text: 'Email',
        },
        {
          text: 'Confirmación de que desea cancelar y/o borrar su cuenta SEP',
        },
      ],
      note: {
        text_one: 'Nota: ',
        text_two: 'La opción de solicitar borrar su información personal está disponible solo para residentes de California.',
      },
      go_back: 'Regresar',
    },
    edit_cta_title: "Mi Información",
    missing_information: "Te falta información, por favor completa tu perfil.",
    edit_cta: "Editar",
    cancel_edit_cta: "Cancelar",
    submit_cta: "Aplicar cambios",
    next_payment: "Fecha límite de pago",
    payment_cta_title: "Facturación",
    plan_name: "Nombre del plan",
    plan_information: "Información de mi plan",
    plan_type: "Tipo de plan",
    payPastDue_cta: "Pagar ahora",
    gender_title: "Género",
    member_since: 'Activa desde',
    basic_member: {
      upgrade_premium_message: "Upgrade to premium to unlock all benifits for",
      unlock_benifit_cta: "Unlock benifits now!",
      duration: "/ month"
    },
    podercard_alert: {
      title: "¡Oh!",
      subtitle: "Como tarjetahabiente PODERcard, sólo puede hacer estos cambios en esa app.",
      open: "Abrir PODERcard",
      cancel: "Cancelar",
    },
    sms_preferences: {
      account_cancellation: {
        text_one: 'Haga clic ',
        link: 'aquí ',
        text_two: 'para borrar su cuenta e información personal',
      },
      title: "Ajustes a notificaciones SMS",
      text: 'Puede actualizar la configuración de las notificaciones SMS aquí. Deje las casillas seleccionadas para cada canal SMS del cual desea recibir mensajes. Deje la casilla vacía si prefiere no recibir mensajes de ese canal.',
      sep_rewards: {
        title: 'Recompensas SEP ',
        code: '(55280)',
      },
      sep_info_and_chat: {
        title: 'Info + chat SEP ',
        code: '(77237)',
      },
      sep_marketing: {
        title: 'Ofertas y marketing SEP ',
        code: '(52749)',
      },
      submit_cta: 'Enviar',
    },
    terms_and_condition: "Términos y Condiciones y Política de Privacidad",
  },
  update_location: {
    text: 'Cambiar ubicación',
    location: 'Ciudad, estado o código postal',
    save: 'Actualizar',
    close: 'Close (SP)',
    enter_zip_code: 'Enter zip code (SP)',
    zip_code_validation_error: 'Por favor ingrese un código postal de 5 dígitos',
    drug_validation_error: 'Please select a drug first (SP)',
  },
};

export default user;
