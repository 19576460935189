import axios from 'axios';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as auth from '@separate/utilities/auth';
import requestSigner from '@separate/utilities/requestSigner';

dayjs.extend(isSameOrAfter);

async function refreshIAMTokensIfNeeded() {
  let iamCreds = auth.getTemporaryCredentials();
  const expiration = dayjs.unix(iamCreds?.expiration);

  const fiveMinutesFromNow = dayjs().add(5, 'minute');

  // Refresh the IAM temp token if it is going to expire in 5 mins or less
  if (fiveMinutesFromNow.isSameOrAfter(expiration, 'second')) {
    console.log('go refreshIamToken', expiration.format(), fiveMinutesFromNow.format());
    await requestSigner.refreshToken();
  }
}

export default async function baseAuthAdminQuery(props) {
  const errorStatusesToHandle = [401, 404];

  const {
    path,
    method,
    data = null,
    params = {},
    locale = 'es',
  } = props;

  if (!auth.loggedIn()) { return null; }

  await refreshIAMTokensIfNeeded();
  let options = await requestSigner.sign(path, method, data, locale);
  if (options == null) {
    auth.reset();
    return null;
  }

  try {
    return await axios(options);
  } catch(error) {
    if (errorStatusesToHandle.includes(error?.response?.status)) {
      auth.reset();
      return null;
    }

    throw error;
  }
}
